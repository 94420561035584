import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 577.000000 297.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,297.000000) scale(0.100000,-0.100000)">

<path d="M3319 1811 c-36 -36 -37 -61 -3 -95 52 -52 134 -20 134 52 0 26 -7
36 -32 52 -45 27 -64 25 -99 -9z"/>
<path d="M2344 1593 c7 -199 21 -361 25 -271 2 53 21 59 22 6 1 -24 4 -34 6
-23 11 40 67 61 76 28 3 -10 5 21 6 69 1 105 17 128 87 128 68 0 76 -15 90
-160 7 -65 9 -70 15 -40 l6 35 2 -32 c1 -35 21 -57 21 -24 0 28 18 27 23 -1 2
-13 6 9 7 47 3 53 4 60 7 28 2 -24 8 -43 13 -43 5 0 11 -10 13 -22 2 -13 5 31
6 96 1 110 -1 123 -23 161 -20 34 -34 45 -80 60 -54 19 -101 16 -168 -10 -16
-7 -18 1 -18 84 l0 91 -71 0 -72 0 7 -207z"/>
<path d="M3882 1714 l3 -85 -35 8 c-19 4 -64 7 -98 7 -53 -1 -71 -6 -105 -31
-62 -43 -85 -91 -86 -177 0 -55 4 -79 20 -105 15 -25 23 -30 33 -22 8 7 23 7
47 1 44 -13 58 -12 39 1 -12 8 -9 10 13 6 32 -5 46 8 16 16 -11 3 -23 15 -26
26 -3 12 -8 21 -12 21 -3 0 -7 18 -9 41 -7 100 97 155 170 90 30 -27 33 -34
34 -90 0 -33 3 -63 7 -67 3 -4 2 -14 -3 -24 -8 -16 -3 -18 55 -19 l65 -1 1
175 c0 96 0 202 0 235 0 33 0 65 0 70 -1 6 -30 10 -67 10 l-65 0 3 -86z m-282
-379 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z"/>
<path d="M1053 1588 c4 -99 8 -213 9 -252 1 -70 1 -70 8 -21 l7 50 3 -40 c3
-34 4 -30 10 25 l7 65 1 -55 c2 -39 5 -51 12 -40 8 13 11 12 24 -5 15 -19 15
-18 17 20 l1 40 13 -45 c13 -44 13 -43 14 63 1 122 9 131 50 58 15 -27 26 -39
31 -31 5 8 9 7 13 -5 4 -9 3 -13 -3 -10 -15 9 -12 -12 4 -27 7 -8 21 -29 30
-46 10 -20 21 -30 28 -26 8 5 9 1 5 -10 -4 -11 -3 -15 4 -10 7 4 9 23 6 47 -3
23 -2 35 2 28 7 -11 9 -11 13 0 3 8 10 -4 16 -25 6 -22 8 -44 5 -49 -3 -6 -1
-7 6 -3 6 4 9 16 6 26 -3 10 -1 21 5 25 5 3 10 1 10 -6 0 -6 9 0 20 14 11 14
20 28 20 30 0 3 18 35 41 71 40 64 62 76 58 33 0 -12 0 -62 2 -112 l2 -90 7
65 c6 59 7 61 9 20 2 -35 4 -41 11 -25 8 19 9 19 9 1 1 -15 4 -16 16 -6 12 10
16 10 19 -1 2 -8 7 -21 9 -29 3 -8 6 5 6 30 1 51 18 44 23 -10 2 -19 5 74 6
208 l2 242 -54 0 c-59 0 -58 1 -113 -97 -14 -26 -36 -64 -47 -84 -12 -19 -34
-59 -51 -87 -16 -29 -36 -52 -42 -52 -7 0 -20 15 -29 33 -9 17 -36 64 -59 102
-22 39 -56 96 -75 128 l-34 57 -60 0 -59 0 6 -182z m74 -200 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m193 -28 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M4707 1734 c-4 -4 -7 -22 -7 -40 0 -49 -9 -64 -40 -64 -26 0 -27 -2
-27 -50 l0 -50 33 0 34 0 0 -94 c0 -52 5 -107 11 -123 13 -36 58 -83 78 -83
12 1 11 3 -4 12 -29 16 -68 62 -56 66 6 2 11 17 11 33 0 41 28 39 32 -3 2 -21
9 -32 21 -34 13 -2 17 2 13 12 -8 20 11 17 23 -3 6 -10 11 -14 11 -9 0 5 7 3
15 -4 25 -21 17 1 -10 26 -25 23 -26 28 -21 111 2 49 5 89 5 91 1 1 24 2 51 2
l50 0 0 49 0 50 -47 3 -48 3 -5 50 -5 50 -55 3 c-31 2 -59 0 -63 -4z"/>
<path d="M1895 1634 c-74 -23 -82 -34 -60 -84 14 -30 23 -39 34 -34 86 36 161
35 188 -2 27 -36 18 -42 -72 -45 -79 -2 -119 -12 -138 -31 -45 -45 -59 -96
-36 -128 8 -11 9 -7 4 17 -8 38 7 77 18 49 4 -10 3 -15 -3 -11 -6 3 -10 1 -10
-5 0 -15 26 -41 34 -33 3 4 6 1 6 -5 0 -8 7 -9 25 -2 17 6 25 6 25 -1 0 -6 4
-8 9 -5 5 3 7 14 5 24 -10 36 20 56 82 57 50 0 59 -3 62 -19 6 -29 -18 -64
-49 -71 -20 -5 -26 -11 -22 -22 3 -8 9 -10 12 -5 9 15 68 42 75 35 3 -4 6 -1
6 5 0 6 7 9 15 6 9 -4 15 0 15 7 1 9 6 7 15 -5 8 -11 17 -18 19 -15 2 2 8 33
13 69 l10 65 1 -94 c1 -113 11 -134 14 -30 1 41 3 94 4 119 5 104 -35 170
-119 195 -63 19 -117 18 -182 -1z m35 -224 c12 -8 11 -10 -7 -10 -13 0 -23 5
-23 10 0 13 11 13 30 0z"/>
<path d="M3125 1640 c-16 -5 -40 -12 -52 -15 -15 -5 -23 -3 -23 4 0 7 -22 11
-60 11 l-60 0 -1 -32 c-2 -111 2 -353 6 -357 3 -2 5 28 5 68 0 42 4 70 9 66 5
-3 8 -20 6 -38 -2 -28 1 -33 20 -35 14 -1 26 5 30 16 6 16 7 16 21 -2 10 -14
14 -16 14 -5 0 12 2 12 10 -1 7 -11 10 -6 10 20 0 26 -3 31 -10 20 -6 -9 -10
-10 -10 -3 0 6 5 15 10 18 6 3 10 29 10 56 0 66 30 99 90 99 l40 0 0 60 c0 65
-2 67 -65 50z m-178 -232 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4245 1637 c-56 -15 -98 -35 -93 -45 42 -85 52 -94 74 -72 5 5 32 14
59 19 66 12 115 -6 115 -43 0 -25 -2 -26 -66 -26 -135 0 -188 -35 -189 -125 0
-30 5 -59 12 -66 9 -9 11 -8 7 7 -4 13 4 24 25 37 34 20 42 21 38 5 -1 -7 3
-12 10 -13 6 0 15 -3 19 -7 4 -5 14 -8 23 -8 14 1 13 2 -1 11 -11 6 -18 22
-18 39 0 25 5 30 38 39 20 6 57 11 81 11 l44 0 -6 -39 c-5 -38 -4 -39 29 -43
28 -3 34 -1 34 15 0 10 -4 15 -10 12 -5 -3 -10 1 -10 9 0 9 7 16 15 16 10 0
16 -11 17 -32 1 -22 4 -15 8 22 l7 55 1 -46 c2 -33 6 -46 17 -46 21 0 14 191
-9 236 -38 73 -157 107 -271 78z m272 -179 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m-257 -58 c-20 -13 -33 -13 -25 0 3 6 14 10 23 10 15 0 15
-2 2 -10z m230 -12 c0 -6 -6 -5 -15 2 -8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4
15 -11 15 -16z m-333 -70 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M5142 1634 c-29 -8 -60 -21 -68 -29 -14 -14 -14 -19 3 -55 21 -45 23
-46 66 -23 67 34 167 11 167 -39 0 -16 -9 -18 -69 -18 -37 0 -83 -5 -102 -11
-36 -12 -107 -81 -89 -87 8 -3 7 -8 -1 -18 -10 -12 -9 -18 4 -32 16 -15 17
-14 17 12 0 29 0 29 22 10 l21 -19 -6 33 c-4 19 -2 32 4 32 6 0 7 -4 4 -10 -3
-5 -1 -10 5 -10 6 0 11 -12 10 -27 -3 -42 -1 -53 9 -53 5 0 7 10 4 23 -4 21
-4 21 11 3 9 -11 18 -17 21 -14 9 9 -6 38 -20 38 -7 0 -15 9 -18 20 -6 22 8
28 16 8 3 -9 9 -7 19 6 11 14 29 19 74 20 55 1 59 -1 62 -23 2 -13 -2 -35 -9
-47 -7 -13 -9 -27 -6 -31 4 -3 7 1 7 10 0 10 5 17 11 17 6 0 9 -9 6 -20 -8
-32 10 -23 22 10 11 32 46 41 69 18 7 -7 12 -8 12 -2 0 5 5 2 10 -6 17 -26 12
196 -5 229 -45 88 -153 120 -283 85z m45 -230 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m153 -58 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5
11 10 11 6 0 10 -2 10 -4z"/>
<path d="M3309 1618 c-2 -81 3 -247 8 -266 4 -18 8 -21 14 -10 6 9 9 6 10 -12
l1 -25 8 25 c5 17 8 19 9 8 1 -10 6 -18 12 -18 5 0 7 5 4 10 -3 6 3 10 15 10
35 0 50 54 50 185 l0 115 -65 0 c-61 0 -65 -2 -66 -22z"/>
<path d="M3860 1345 c-10 -12 -10 -15 3 -15 19 0 31 16 18 24 -5 3 -14 -1 -21
-9z"/>
<path d="M3400 1319 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3795 1321 c-26 -4 -40 -11 -40 -21 0 -8 -7 -18 -15 -22 -13 -6 -13
-7 0 -7 8 0 22 4 30 9 13 9 13 11 0 21 -11 7 -3 9 31 7 34 -2 45 0 42 10 -3 6
-6 11 -7 11 0 -1 -19 -5 -41 -8z"/>
<path d="M4903 1323 c-7 -2 -13 -12 -12 -21 0 -11 2 -12 6 -4 2 6 11 12 19 12
8 0 14 5 14 10 0 11 -7 12 -27 3z"/>
<path d="M5355 1309 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M1930 1299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4368 1303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4511 1293 c-1 -16 5 -23 17 -23 10 0 13 3 6 8 -6 4 -14 14 -17 22
-4 10 -6 8 -6 -7z"/>
<path d="M4318 1293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4915 1290 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M5208 1293 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1135 1280 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1830 1270 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M3312 1270 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5072 1270 c7 -11 19 -22 28 -25 8 -4 4 5 -9 19 -29 31 -36 33 -19 6z"/>
<path d="M4338 1273 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2053 1253 c-7 -2 -13 -9 -13 -15 0 -7 5 -7 13 1 8 6 16 8 19 5 3 -3
9 -1 13 5 6 11 -10 13 -32 4z"/>
<path d="M4194 1245 c11 -8 27 -15 35 -15 9 0 3 7 -13 15 -36 19 -47 19 -22 0z"/>
<path d="M4470 1245 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M1865 1240 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
<path d="M3835 1240 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M4930 1240 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5130 1236 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M1145 1085 c-63 -29 -95 -73 -95 -130 0 -74 61 -135 135 -135 27 0
99 33 109 49 13 20 -13 20 -51 -1 -47 -26 -83 -20 -124 22 -23 23 -29 37 -29
72 0 83 76 124 154 83 18 -10 33 -12 39 -6 14 14 -1 31 -39 47 -41 17 -61 17
-99 -1z"/>
<path d="M1463 1074 c-43 -21 -55 -33 -69 -69 -18 -50 -13 -98 14 -130 49 -58
105 -69 173 -35 79 41 105 138 52 201 -26 30 -77 59 -106 59 -7 0 -36 -12 -64
-26z m128 -45 c54 -58 31 -158 -40 -174 -89 -19 -165 91 -114 165 24 35 33 40
81 40 36 0 49 -5 73 -31z"/>
<path d="M1778 1085 l-28 -16 0 -125 c0 -96 3 -125 13 -122 8 3 14 40 17 119
5 110 6 114 28 117 32 5 71 -23 88 -63 8 -19 14 -66 14 -105 0 -53 3 -70 14
-70 21 0 26 23 26 109 0 69 3 81 25 105 19 21 33 27 58 24 l32 -3 3 -117 c2
-92 6 -118 16 -118 21 0 26 24 26 125 0 108 -10 142 -43 150 -26 7 -90 -16
-119 -42 -16 -14 -20 -13 -48 11 -18 14 -37 26 -43 26 -7 0 -21 2 -32 5 -11 3
-32 -2 -47 -10z"/>
<path d="M2216 1079 c-26 -20 -26 -23 -26 -140 0 -109 2 -120 18 -117 15 3 17
18 20 121 2 103 5 117 20 117 73 -1 102 -46 102 -159 0 -73 2 -82 18 -79 14 3
18 18 22 95 4 80 8 95 30 118 18 19 32 25 55 23 l30 -3 5 -115 c4 -93 8 -115
20 -115 24 0 27 230 4 256 -10 11 -22 19 -28 19 -30 0 -84 -22 -107 -44 l-27
-25 -18 21 c-20 22 -73 48 -97 48 -8 0 -26 -9 -41 -21z"/>
<path d="M2634 1086 c-3 -8 -4 -60 -2 -115 2 -80 7 -103 22 -120 40 -44 122
-33 160 21 19 27 23 212 5 224 -20 12 -29 -20 -29 -111 0 -127 -30 -164 -93
-115 -25 20 -26 27 -29 122 -2 70 -7 103 -15 106 -7 2 -16 -3 -19 -12z"/>
<path d="M2955 1088 c-33 -18 -35 -28 -35 -151 0 -91 3 -118 13 -115 8 3 14
40 17 119 l5 114 27 3 c18 2 37 -6 60 -27 32 -29 33 -32 36 -120 2 -50 7 -91
12 -91 24 0 32 34 28 109 -5 89 -26 128 -82 155 -40 19 -54 19 -81 4z"/>
<path d="M3213 1093 c-20 -7 -18 -273 1 -273 22 0 26 24 26 150 0 118 -3 132
-27 123z"/>
<path d="M3407 1077 c-50 -30 -77 -73 -77 -120 0 -75 58 -137 129 -137 36 0
107 35 115 57 8 19 -14 16 -49 -7 -39 -26 -76 -25 -110 2 -56 43 -64 119 -19
169 22 24 86 25 125 2 21 -13 32 -14 40 -6 16 16 4 31 -38 48 -48 20 -72 19
-116 -8z"/>
<path d="M3723 1085 c-49 -21 -63 -59 -63 -170 0 -86 2 -96 18 -93 12 2 18 16
22 48 l5 45 57 3 57 3 3 -48 c2 -35 7 -48 18 -48 12 0 16 19 18 100 3 109 -4
132 -48 158 -34 20 -45 21 -87 2z m79 -42 c11 -10 18 -30 18 -55 l0 -38 -60 0
-60 0 0 36 c0 52 15 74 52 74 17 0 40 -7 50 -17z"/>
<path d="M3963 1093 c-7 -2 -13 -11 -13 -19 0 -10 12 -14 40 -14 l40 0 0 -120
c0 -96 3 -120 14 -120 22 0 26 23 26 136 0 98 1 104 21 104 28 0 61 19 54 31
-6 9 -160 12 -182 2z"/>
<path d="M4252 1088 c-8 -8 -12 -54 -12 -141 0 -117 2 -128 18 -125 15 3 17
19 20 132 3 128 -3 157 -26 134z"/>
<path d="M4442 1073 c-92 -60 -97 -178 -9 -232 44 -27 92 -26 142 2 103 58 96
197 -12 243 -47 20 -74 17 -121 -13z m140 -43 c45 -55 34 -134 -23 -164 -44
-24 -80 -16 -120 24 -34 34 -39 91 -12 130 26 38 34 41 87 37 36 -3 55 -11 68
-27z"/>
<path d="M4755 1075 c-24 -23 -25 -30 -25 -140 0 -106 2 -116 18 -113 15 3 17
18 20 121 l3 117 29 0 c16 0 41 -11 57 -24 26 -23 28 -31 33 -118 4 -74 8 -93
20 -93 12 0 15 17 15 97 0 89 -2 99 -25 124 -26 27 -77 54 -105 54 -9 0 -27
-11 -40 -25z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
